import { styled } from 'styletron-react'

export const PageContainer = styled('div', {
  width: '100%',
  height: '100vh',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const FormContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexBasis: '50%',
  padding: '16px',
})
