import { styled } from 'styletron-react'

export const MainHeading = styled('h1', {
  marginTop: 0,
  marginBottom: '15px',
  color: '#FFF',
  fontWeight: 400,
  lineHeight: 1,
})

export const DateHeading = styled('p', {
  color: '#FFF',
  marginBottom: '10px',
  marginTop: 0,
  display: 'inline-flex',
  paddingRight: '5px',
})

export const ReportHeading = styled('h3', {
  marginTop: 0,
  marginBottom: '10px',
  fontWeight: 500,
  lineHeight: 1,
})

export const ReportValue = styled('p', {
  marginTop: 0,
  marginBottom: '10px',
  fontWeight: 'bold',
  lineHeight: 1.1,
})
