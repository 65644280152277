import React from 'react'

import DashboardPage from '../../components/dashboard'

import { PageContainer, FormContainer } from './components.styled'

const Dashboard = () => {
  return (
    <PageContainer>
      <FormContainer>
        <DashboardPage />
      </FormContainer>
    </PageContainer>
  )
}
export default Dashboard
