import React from 'react'
import PropTypes from 'prop-types'
import { Auth as AmplifyAuth, Hub } from 'aws-amplify'

import actions from './actions'

const initialState = {
  isLoading: false,
  user: null,
  isGuest: true,
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.RESET_USER_DATA:
      return {
        ...state,
        user: null,
        isGuest: true,
      }
    case actions.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
      }
    case actions.UPDATE_STATE:
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    default:
      return state
  }
}

const AuthContext = React.createContext(null)

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [token, setToken] = React.useState(null)

  /** METHODS */
  const fetchParkHolidaysUser = async () => {
    dispatch({ type: actions.FETCH_INIT })
    return AmplifyAuth.currentAuthenticatedUser()
      .then(user => {
        // Here we'll massage the data into a format that works
        // well for application use, and spreads non-custom claim
        // data for future attribute mapping
        const {
          signInUserSession: {
            idToken: {
              payload: { custom, ...spreadPayload },
            },
          },
        } = user
        const newUser = {
          ...spreadPayload,
          ...JSON.parse(atob(custom)),
        }

        if(user.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')){
          dispatch({
            type: actions.FETCH_SUCCESS,
            payload: { user: newUser, isGuest: false },
          })
        } else {
          dispatch({
            type: actions.FETCH_SUCCESS,
            payload: { user: newUser, isGuest: true },
          })
        }

      })
      .catch(exception => ({ ok: false, ...exception }))
  }

  const signIn = async (emailAddress, password) =>
    AmplifyAuth.signIn(emailAddress, password)
      .then(data => {
        const {
          signInUserSession: {
            idToken: { jwtToken = null },
          },
        } = data

        localStorage.removeItem('signInLastAttemptedUsername')
        setToken(jwtToken)

        if(data.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')){
          return {
            ok: true,
            data,
          }
        } else {
          return {
            ok: false,
            message: 'You do not have permission to login into this application'
          }
        }
      })
      .catch(exception => ({
        ok: false,
        ...exception,
        attemptsExceeded: exception.message === 'Password attempts exceeded',
      }))

  const signOut = async () =>
    AmplifyAuth.signOut()
      .then(data => ({ ok: true, data }))
      .catch(exception => ({ ok: false, ...exception }))

  const forgotPassword = async emailAddress =>
    AmplifyAuth.forgotPassword(emailAddress)
      .then(data => ({ ok: true, data }))
      .catch(exception => ({ ok: false, ...exception }))

  const resetPassword = async (username, code, newPassword) =>
    AmplifyAuth.forgotPasswordSubmit(username, code, newPassword)
      .then(data => ({ ok: true, data }))
      .catch(exception => ({ ok: false, ...exception }))

  /** USE EFFECTS */
  React.useEffect(() => {
    // Add event listener
    Hub.listen('auth', data => {
      const { payload } = data
      switch (payload.event) {
        case 'signIn':
          fetchParkHolidaysUser()
          break
        case 'signOut':
          dispatch({ type: actions.RESET_USER_DATA })
          localStorage.clear()
          setToken(null)
          break
        case 'tokenChanged':
          setToken(payload.data)
          break
        default:
          break
      }
    })

    return () => Hub.remove('auth')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const localToken = localStorage.getItem('jwtToken')

    if (!token) {
      if (localToken) setToken(localToken)
    } else {
      localStorage.setItem('jwtToken', token)
      fetchParkHolidaysUser()
    }

    return () => {}
  }, [token])

  // React.useEffect(() => {
  //   localStorage.setItem('signInAttemptsRemaining', state.attemptsRemaining);
  //   return () => {};
  // }, [state.attemptsRemaining]);

  // Create new object that contains state and all the methods
  const newState = {
    ...state,
    signIn,
    signOut,
    forgotPassword,
    resetPassword,
  }

  return (
    <AuthContext.Provider value={{ ...newState }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default AuthContext
