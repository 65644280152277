import { styled } from 'styletron-react'

export const Card = styled('div', {
  padding: '32px',
  backgroundColor: 'rgb(12, 85, 149)',
  height: '100%',
  width: '90%',
  maxWidth: '750px',
})

export const DateRange = styled('div', {
  height: '100%',
})

export const RightDateRange = styled('div', {
  height: '100%',
  float: 'right'
})

export const TotalOwnersByDateRangeReport = styled('div', {
  width: '49%',
  display: 'inline-grid',
  marginRight: '10px',
  backgroundColor: '#FFF',
  marginBottom: '15px',
  borderRadius: '3px',
  padding: '10px',
}) 

export const TotalOwnersReport = styled('div', {
  width: '49%',
  display: 'inline-grid',
  backgroundColor: '#FFF',
  float: 'right',
  marginBottom: '15px',
  borderRadius: '3px',
  padding: '10px',
}) 

export const ReportInnerCard = styled('div', {
  position: 'relative',
  padding: '10px',
  backgroundColor: '#FFF',
  borderRadius:'3px',
  marginBottom: '15px',
}) 

export const ReportContent = styled('div', {
  position: 'relative',
  columnCount: '4',
  maxWidth: '100%',
  marginTop: '10px',
  paddingBottom: '10px',
}) 
