import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DatePicker from 'react-datepicker'

import "react-datepicker/dist/react-datepicker.css";

import { NavButton, Card, DateRange, RightDateRange, ReportInnerCard, ReportHeading, DateHeading, MainHeading, ReportValue, ReportContent, TotalOwnersByDateRangeReport, TotalOwnersReport } from '../styled'

const DashboardPage = () => {
  const [newUsers, setNewUsers] = useState(null)
  const [totalUsers, setTotalUsers] = useState(null)
  const [totalUsersByPark, setTotalUsersByPark] = useState([])
  const [isNewUsersLoading, setIsNewUsersLoading] = useState(false)
  const [isTotalUsersLoading, setIsTotalUsersLoading] = useState(false)
  const [isParkUsersLoading, setIsParkUsersLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [endDate, setEndDate] = useState(new Date(Date.now() + ( 3600 * 1000 * 24)))

  const getNewUsers = async (startDate, endDate) => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-reports/total-new-users', {
      params: {
        'startDate': startDate,
        'endDate': endDate
      }
    })
  }
  const getTotalUsers = async () => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-reports/total-users')
  }

  const getTotalUsersByPark = async (startDate, endDate) => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-reports/total-users-by-park', {
      params: {
        'startDate': startDate,
        'endDate': endDate
      }
    })
  }

  const getReportData = async (startDate, endDate) => {
    setIsNewUsersLoading(true)
    setIsTotalUsersLoading(true)
    setIsParkUsersLoading(true)

    getNewUsers(startDate, endDate)
      .then(response => {
        if (response !== null) {
          setNewUsers(response.data)
          setIsNewUsersLoading(false)
        } else
          setNewUsers('Error')
      })
      .catch(error => {
        console.log(error)
      })
    
    getTotalUsers()
      .then(response => {
        if (response !== null) {
          setTotalUsers(response.data)
          setIsTotalUsersLoading(false)
        } else
          setTotalUsers('Error')
      })
      .catch(error => {
        console.log(error)
      })

    getTotalUsersByPark(startDate, endDate)
      .then(response => {
        if (response !== null) {
          setTotalUsersByPark(response.data)
          setIsParkUsersLoading(false)
        } else
          setTotalUsersByPark('Error')
      })
      .catch(error => {
        console.log(error)
      })
  }

  const changeDates = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)

    const startingDate = new Date(startDate).toISOString().slice(0,10)
    const endingDate = new Date(endDate).toISOString().slice(0,10)

    getReportData(startingDate, endingDate)
  }

  useEffect(() => {
    getReportData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Card>
      <MainHeading>
        Dashboard
      </MainHeading>
      <DateRange>
          <DateHeading>Start Date</DateHeading>
          <DatePicker className="datepicker" dateFormat="dd/MM/yyyy" selected={startDate} onChange={startDate => changeDates(startDate, endDate)} minDate={new Date('09/22/2020')}/>
          <RightDateRange>
            <DateHeading>End Date</DateHeading>
            <DatePicker className="datepicker" dateFormat="dd/MM/yyyy" selected={endDate} onChange={endDate => changeDates(startDate, endDate)} />
          </RightDateRange>
      </DateRange>
      <TotalOwnersByDateRangeReport>
        <ReportHeading>
          New Owners Area Users
        </ReportHeading>
        <br/>
        <ReportContent>
          {isNewUsersLoading ? ( 
            <ReportValue>Loading...</ReportValue> 
          ) : <ReportValue>{newUsers}</ReportValue>}
        </ReportContent>
      </TotalOwnersByDateRangeReport>
      <TotalOwnersReport>
        <ReportHeading>
          Total Owners Area Users
        </ReportHeading>
        <br/>
        <ReportContent>
          {isTotalUsersLoading ? (
            <ReportValue>Loading...</ReportValue> 
          ) : <ReportValue>{totalUsers}</ReportValue>}
        </ReportContent>
      </TotalOwnersReport>
      <ReportInnerCard>
        <ReportHeading>
          Total Owners Area Users By Park
        </ReportHeading>
        <br/>
          {isParkUsersLoading ? (
            <ReportContent>
              <ReportValue>Loading...</ReportValue>
            </ReportContent> 
          ) : (
            <ReportContent>
            {totalUsersByPark === 'No data for this date range' ? (
              <ReportValue>0</ReportValue>
            ) : (
              totalUsersByPark.map(park => {
                return <ReportValue key={park.parkCode}>{`${park.parkCode} ${park.totalUsers}`}</ReportValue>
              })
            )}
            </ReportContent>
          )}
      </ReportInnerCard>
      <NavButton $block 
        $as={Link}
        to="/manage-account"
      >
        User Management
      </NavButton>
      <NavButton $block 
        $as={Link}
        to="/logout"
      >
        Logout
      </NavButton>
    </Card>
  )
}

export default DashboardPage
