import { styled } from 'styletron-react'

const nonBlockTypes = ['checkbox', 'radio']

export const Input = styled('input', props => ({
  display: nonBlockTypes.includes(props.type) ? 'initial' : 'block',
  width: nonBlockTypes.includes(props.type) ? 'auto' : '100%',
  maxWidth: '100%',
  padding: nonBlockTypes.includes(props.type) ? 0 : '10px 16px',
  borderRadius: '3px',
  border: 'none',
  fontFamily: 'Raleway',
  marginBottom: '8px',
}))
