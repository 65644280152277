import React, { useState } from 'react'

import AccountSearch from '../../components/account-search'
import AccountForm from '../../components/account-form'
import Notification from '../../components/notification'

import { PageContainer, FormContainer } from './components.styled'

const ManageAccount = () => {
  const [userAccount, setUserAccount] = useState(null)
  const [notification, setNotification] = useState(null)

  const handleOnDeleteSuccess = () => {
    setUserAccount(null)
    setNotification({
      context: 'success',
      message: 'The account was successfully deleted.',
    })
  }

  return (
    <PageContainer>
      <FormContainer>
        {notification ? (
          <Notification
            {...notification}
            onClose={() => setNotification(null)}
          />
        ) : null}

        <AccountSearch onSearchSuccess={setUserAccount} />
      </FormContainer>

      {userAccount ? (
        <FormContainer>
          <AccountForm
            account={userAccount}
            onClose={() => setUserAccount(null)}
            onDeleteSuccess={handleOnDeleteSuccess}
          />
        </FormContainer>
      ) : null}
    </PageContainer>
  )
}
export default ManageAccount
