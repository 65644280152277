import { styled, withStyle } from 'styletron-react'

export const Button = styled('button', props => ({
  display: props.$block ? 'block' : 'initial',
  width: props.$block ? '100%' : 'auto',
  marginBottom: props.$block ? '10px' : '0',
  padding: '10px 16px',
  border: 'none',
  cursor: props.disabled ? 'cancel' : 'pointer',
  lineHeight: 1,
  color: '#FFF',
  borderRadius: '3px',
  backgroundColor: 'rgb(8, 55, 95)',
  fontFamily: 'Raleway',
  ':hover': {
    backgroundColor: props.disabled ? null : 'rgb(0, 25, 65)',
  },
  ':visited': {
    color: props.disabled ? null : '#FFF',
  },
  fontSize: '18px'
}))

export const NavButton = styled('button', props => ({
  display: props.$block ? 'block' : 'initial',
  width: props.$block ? '100%' : 'auto',
  marginBottom: props.$block ? '10px' : '0',
  padding: '10px 16px',
  cursor: props.disabled ? 'cancel' : 'pointer',
  lineHeight: 1,
  color: '#FFF',
  borderRadius: '3px',
  backgroundColor: 'rgb(8, 55, 95)',
  ':hover': {
    backgroundColor: props.disabled ? null : 'rgb(0, 25, 65)',
  },
  ':visited': {
    color: props.disabled ? null : '#FFF',
  },
  textDecoration: 'none',
  textAlign: 'center'
}))

export const RedButton = withStyle(Button, props => ({
  backgroundColor: props.disabled ? null : 'red',
  color: props.disabled ? null : 'white',
  border: props.disabled ? null : '1px solid #d60404',
  ':hover': {
    backgroundColor: props.disabled ? null : '#d60404',
  },
}))

export const CloseButton = styled('button', {
  position: 'absolute',
  top: '5px',
  right: '5px',
  fontSize: 'large',
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
})
