import React, { useState, useContext } from 'react'
import { useStyletron } from 'styletron-react'
import { Redirect } from 'react-router-dom'

import AuthContext from '../../context/auth'
import Notification from '../notification'
import { Form, Label, Input, FormGroup, Button } from '../styled'

const LoginForm = () => {
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [notifications, setNotifications] = useState([])
  const [isLoggingIn, setisLoggingIn] = useState(false)
  const auth = useContext(AuthContext)
  const [css] = useStyletron()

  const handleLogin = async event => {
    setisLoggingIn(true)
    event.preventDefault()
    let newNotifications = []
    const status = await auth.signIn(emailAddress, password)

    if (!status.ok) {
      setisLoggingIn(false)
      newNotifications.push({
        context: 'error',
        pale: true,
        message: [status.message].concat(
          status.attemptsExceeded
            ? ['. Your account is now locked, please try again in 5 minutes.']
            : [],
        ),
      })
    }
    setNotifications(newNotifications)
  }

  return auth.isGuest ? (
    <Form onSubmit={handleLogin}>
      <FormGroup>
        {notifications ? (
          notifications.map(notification => (
            <Notification
              {...notification}
              onClose={() => setNotifications(null)}
            />
          ))
        ) : null}
        <h1
          className={css({
            marginTop: 0,
            marginBottom: '10px',
            color: '#FFF',
            textTransform: 'uppercase',
            fontWeight: 400,
            lineHeight: 1,
          })}
        >
          Login
        </h1>
        <Label htmlFor="email">Email:</Label>
        <Input
          type="email"
          name="email"
          value={emailAddress}
          placeholder="e.g. joebloggs@gmail.com"
          tabIndex={0}
          onChange={e => setEmailAddress(e.target.value)}
          required
        />
        <Label htmlFor="email">Password:</Label>
        <Input
          type="password"
          name="password"
          value={password}
          tabIndex={0}
          onChange={e => setPassword(e.target.value)}
          required
        />
      </FormGroup>

      <Button type="submit" tabIndex={1} disabled={isLoggingIn} $block>
        {isLoggingIn ? 'Logging In..' : 'Login'}
      </Button>
    </Form>
  ) : (
    <Redirect to={'/'} />
  )
}

export default LoginForm
