import axios from 'axios'
import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'

import Notification from '../../components/notification'

import { useEffect } from 'react'
import {
  Button, CloseButton, Form, FormGroup, Input, Label, MainHeading, RedButton
} from '../styled'

const AccountForm = ({ account, onClose, onDeleteSuccess }) => {
  const [enableDelete, setEnableDelete] = useState(false)
  const [emailAddress, setEmailAddress] = useState(account.email)
  const [contactNo, setContactNo] = useState(account.contactNo)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [notification, setNotification] = useState(null)
  const [css] = useStyletron()

  const hasChanged =
    (emailAddress !== account.email && emailAddress !== '') || (contactNo !== account.contactNo && contactNo !== '') ||
    newPassword !== ''

  console.log(account);

  const changeUserEmail = async () => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-management/change-email', {
      params: {
        'username': account.id,
        'oldEmail': account.email,
        'newEmail': emailAddress,
        'brand': account.brand
      }
    })
  }

  const changeUserContactNo = async () => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-management/change-contact', {
      params: {
        'username': account.id,
        contactNo
      }
    })
  }

  const changeUserPassword = async () => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-management/change-password', {
      params: {
        'username': account.id,
        'tempPassword': newPassword,
        'brand': account.brand
      }
    })
  }

  const confirmUserAccount = async () => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-management/confirm-account', {
      params: {
        'email': account.email,
        'username': account.id,
        'contactNo': contactNo,
        'brand': account.brand
      }
    })
  }

  const deleteUserAccount = async () => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-management/delete-account', {
      params: {
        'username': account.id,
        'brand': account.brand
      }
    })
  }

  const updateAccountCredentials = () => {
    if (account.status === 'UNCONFIRMED') {
      if(contactNo) {
        confirmUserAccount(account.email, account.username, contactNo, account.brand)
          .then(() =>
            setNotification({
              context: 'success',
              message: 'This account has been confirmed.',
            }),
            )
            .catch(error => {
              console.log(error)
              setNotification({
                context: 'error',
                message:
                'There was a problem with confirming this account, please check the details and try again.',
              })
            })
      } else {
        setNotification({
          context: 'error',
          message:
            'Please specify a Contact No.',
        })
      }
    } else {
      if (emailAddress !== account.email)
        changeUserEmail(account.id, emailAddress)
          .then(() =>
            setNotification({
              context: 'success',
              message: 'The accounts email address has been updated.',
            }),
          )
          .catch(error => {
            console.log(error)
            setNotification({
              context: 'error',
              message:
                'There was a problem updating the account email address, please check the details and try again.',
            })
          })

      if (contactNo !== account.contactNo)
        changeUserContactNo(account.id, contactNo)
          .then(()=>
            setNotification({
              context: 'success',
              message: 'The accounts contact no has been updated.'
            }),
          )
          .catch(error => {
            console.log(error)
            setNotification({
              context: 'error',
              message:
                'There was a problem updating the account contact no, please check the details and try again.',
            })
          })

      if (newPassword !== '')
        changeUserPassword()
          .then(() => {
            setNotification({
              context: 'success',
              message: 'The accounts password has been updated.',
            })

            setNewPassword('')
            setConfirmPassword('')
          })
          .catch(error => {
            console.log(error)
            setNotification({
              context: 'error',
              message:
                'There was a problem updating the account password, please check the details and try again.',
            })
          })
    }
  }

  const handleDeleteAccount = event => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      'WARNING! You are about to permanently delete this account. Please make sure this is the action you wish to take, as once actioned it can not be undone.\n\nIf you are sure you wish to continue then click OK, otherwise click Cancel',
    )

    if (confirmed)
      deleteUserAccount()
        .then(() => onDeleteSuccess())
        .catch(error => {
          console.log(error)
          setNotification({
            context: 'error',
            message:
              'There was a problem deleting the account, please check the details and try again.',
          })
        })

    setEnableDelete(false)
  }

  const handleOnSubmit = event => {
    event.preventDefault()

    /** SIMPLE check for now further validation will be necessary */
    let isValid = hasChanged && confirmPassword === newPassword
    if (isValid) updateAccountCredentials()
  }

  useEffect(() => {
    setEmailAddress(account.email)
    account.contactNo ? setContactNo(account.contactNo) : setContactNo('')
  }, [account])

  return (
    <>
      <Form onSubmit={handleOnSubmit}>
        <CloseButton type="button" onClick={onClose}>
          &times;
        </CloseButton>
        <MainHeading>Edit {account.brand === 'PH' ? "Park Holidays" : "Park Leisure"} account</MainHeading>
        <span>ID: {account.id}</span>
        <br />
        <span>Account Status: {account.status}</span>
        <br />
        <br />

        {notification ? (
          <Notification
            {...notification}
            onClose={() => setNotification(null)}
          />
        ) : null}


        <FormGroup>
          <Label>Email Address</Label>
          <Input
            type="email"
            name="userEmail"
            value={emailAddress}
            disabled={account.status === 'UNCONFIRMED'}
            onChange={e => setEmailAddress(e.target.value)}
            required
          />
        </FormGroup>

        <hr className={css({ margin: '16px 0' })} />

        <FormGroup>
          <Label>Contact No</Label>
          <Input
            type="text"
            name="userContactNo"
            value={contactNo}
            disabled={false}
            onChange={e => setContactNo(e.target.value)}
            required
          />
        </FormGroup>


        {account.status === 'UNCONFIRMED' ? (
          <div>
            <Button type="submit" disabled={!contactNo} $block>
              Confirm Account
            </Button>
          </div>
          ) : (
            <div>
              <hr className={css({ margin: '16px 0' })} />
              <FormGroup>
                <Label>New Password</Label>
                <Input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
                  onChange={e => setNewPassword(e.target.value)}
                />
                <small>
                  Must be at least 8 characters in length and contain a combination of
                  lowercase and uppercase letters and numbers.
                </small>
              </FormGroup>

              <FormGroup>
                <Label>Confirm Password</Label>
                <Input
                  type='password'
                  name="confirmPassword"
                  value={confirmPassword}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
                  onChange={e => setConfirmPassword(e.target.value)}
                  required={newPassword !== ''}
                />
                {confirmPassword !== '' && confirmPassword !== newPassword ? (
                  <span className={css({ color: 'red' })}>
                    Passwords do not match
                  </span>
                ) : null}
              </FormGroup>

              <Button type="submit" disabled={!hasChanged} $block>
                Update Account Credentials
              </Button>

              <RedButton
                type="button"
                disabled={!enableDelete}
                onClick={handleDeleteAccount}
                $block
              >
                Delete Account
              </RedButton>

              <Label className={css({ color: 'red', fontWeight: 500 })}>
                <Input
                  type="checkbox"
                  checked={enableDelete}
                  onChange={e => setEnableDelete(e.target.checked)}
                  className={css({ marginRight: '10px' })}
                />
                I wish to delete this account!
              </Label>
            </div>
          )}

      </Form>
    </>
  )
}

export default AccountForm
