import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Notification from '../../components/notification'
import { Button, Form, FormGroup, Input, Label, MainHeading, NavButton } from '../styled'

const SearchForm = ({ onSearchSuccess }) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [notification, setNotification] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [brand, setBrand] = useState('PH')

  const getUserAttributes = async () => {
    return await axios.get('https://accounts.pheme.parkholidays.io/user-management/get-user', {
      params: {
        'email': emailAddress,
        'brand': brand
      }
    })
  }

  const getUserDetails = async () => {
    setIsSearching(true)

    getUserAttributes()
      .then(response => {
        if (response !== null) {
          onSearchSuccess({
            id: response.data.username,
            status: response.data.userStatus,
            contactNo: response.data.contactNo,
            email: emailAddress,
            brand: brand
          })

          setEmailAddress('')
          //setBrand('PH')
          setNotification(null)
        } else{
          setNotification({
            context: 'error',
            message:
              'Unable to find any accounts matching the email address provided.',
          })
        }
        setIsSearching(false)
      })
      .catch(error => {
        setNotification({
          context: 'error',
          message:
            'Unable to find any account matching the email address provided.',
        })
        setIsSearching(false)
      })
  }

  const handleOnSubmit = event => {
    event.preventDefault()
    getUserDetails()
  }

  return (
    <Form onSubmit={handleOnSubmit}>
      <FormGroup>
        <MainHeading>Find an Owner</MainHeading>
        {notification ? (
          <Notification
            {...notification}
            onClose={() => setNotification(null)}
          />
        ) : null}
        <Label htmlFor="brand">
        <Input
          type="radio"
          name="brand"
          value='PH'
          checked={brand === "PH"}
          tabIndex={0}
          onClick={() => setBrand('PH')}
        /> Park Holidays owner
        </Label>
        <Label htmlFor="brand">
        <Input
          type="radio"
          name="brand"
          value='PL'
          checked={brand === "PL"}
          tabIndex={1}
          onClick={() => setBrand('PL')}
        /> Park Leisure owner
        </Label>

        <Label htmlFor="email">Enter an email address</Label>
        <Input
          type="email"
          name="email"
          value={emailAddress}
          placeholder="e.g. joebloggs@gmail.com"
          tabIndex={2}
          onChange={e => setEmailAddress(e.target.value)}
          required
        />
      </FormGroup>

      <Button type="submit" tabIndex={1} disabled={isSearching} $block>
        {isSearching ? 'Searching..' : 'Find Account'}
      </Button>
      <NavButton $block
        $as={Link}
        to="/"
      >
        Back to Dashboard
      </NavButton>
    </Form>
  )
}

export default SearchForm
