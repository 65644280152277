import {
  Login,
  ManageAccount,
  Dashboard,
  Logout
} from './views'

const authRoutes = [
  {
    path: '/login',
    component: Login,
    exact: true,
    isPublic: true,
  },
  {
    path: '/logout',
    component: Logout,
    hideLayout: true,
  },
]

const privateRoutes = [
  {
    path: '/',
    component: Dashboard,
    exact: true,
  },
  {
    path: '/manage-account',
    component: ManageAccount,
    exact: true,
  },
]

export default [...authRoutes, ...privateRoutes]
