import { styled } from 'styletron-react'

export const Form = styled('form', {
  position: 'relative',
  padding: '32px',
  backgroundColor: 'rgb(12, 85, 149)',
  borderRadius: '3px',
  width: '90%',
  maxWidth: '600px',
})
