import { styled } from 'styletron-react'

export const NotificationRoot = styled('div', props => ({
  position: 'relative',
  display: 'block',
  backgroundColor: '#e5e5e5',
  color: '#222',
  border: '1px solid #ddd',
  padding: '8px 32px 8px 16px',
  marginBottom: '10px',
}))
