import React from 'react'
import { Redirect } from 'react-router-dom'

import AuthContext from '../../context/auth'

import { PageContainer } from './components.styled'

const Logout = () => {
  const auth = React.useContext(AuthContext)

  React.useEffect(() => {
    // eslint-disable-next-line no-return-await
    const signOut = async () => await auth.signOut()
    signOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContainer>
      <div
        style={{
          width: '100%',
          maxWidth: '320px',
        }}
      >
        <h2>You are being logged out.</h2>
        <p noMargins>Please wait...</p>
        {auth.isGuest ? <Redirect to="/login" /> : null}
      </div>
    </PageContainer>
  )
}

export default Logout
