import React from 'react'

import LoginForm from '../../components/login-form'

import { PageContainer, FormContainer } from './components.styled'

const Login = () => {
  return (
    <PageContainer>
      <FormContainer>
        <LoginForm />
      </FormContainer>
    </PageContainer>
  )
}
export default Login
