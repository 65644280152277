import React from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

// import ManageAccount from './views/manage-account'
// import Login from './views/login'
import AuthContext from './context/auth'

import routes from './routes'

const AuthenticatedRoute = ({
  component: Component,
  isLoggedIn,
  user,
  routeProps,
  ...rest
}) => {
  const location = useLocation()
  const goToDashboard =
    user && location.pathname !== '/'
  const redirectPath = goToDashboard ? '/' : '/login'

  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? (
          <Redirect
            to={{ pathname: redirectPath, state: { from: location } }}
          />
        ) : (
          <Component {...props} {...routeProps} />
        )
      }
    />
  )
}

const App = () => {
  const auth = React.useContext(AuthContext)

  let newRoutes = [...routes]
  return (
    <Switch>
      {newRoutes.map(route => {
        const Component = route.component
        return route.isPublic ? (
          <Route
              exact={route.exact}
              path={route.path}
              key={route.path}
              render={props => (
                <Component {...props} {...(route.props || {})} />
              )}
            />
          ) : (
            <AuthenticatedRoute
              exact
              path={route.path}
              key={route.path}
              component={route.component}
              isLoggedIn={!auth.isGuest}
              user={auth.user}
              routeProps={route.props}
            />
          )
      })}
    </Switch>
  )
}

export default App
