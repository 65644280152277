import React from 'react'
import { useStyletron } from 'styletron-react'

import { CloseButton } from '../styled'
import { NotificationRoot } from './styled'

const Notification = ({ context, message, onClose }) => {
  const [css] = useStyletron()
  let contextStyle = {}

  switch (context) {
    case 'error':
      contextStyle = {
        backgroundColor: 'red !important',
        borderColor: 'red !important',
        color: 'white !important',
      }
      break
    case 'success':
      contextStyle = {
        backgroundColor: 'green !important',
        borderColor: 'green !important',
        color: 'white !important',
      }
      break
    default:
      break
  }
  return (
    <NotificationRoot className={css(contextStyle)}>
      <CloseButton type="button" onClick={onClose}>
        &times;
      </CloseButton>
      {message}
    </NotificationRoot>
  )
}

export default Notification
